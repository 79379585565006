import React, { useEffect, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import { FreeMode, Navigation } from "swiper/modules";
import http from "../../services/httpServices";
import { baseURL } from "../../config";

const TeacherSlider = () => {
    const [teachers, setTeachers] = useState([]);

    const fetchTeacher = async () => {
        let params = {
            limit: 100,
        };
        try {
            const { data: res } = await http.get("/api/teacher", {
                params,
            });
            setTeachers(res.results);
        } catch (error) {
            console.log("err : ", error);
        }
    };
    useEffect(() => {
        fetchTeacher();
    }, []);

    return (
        <>
            <Swiper
                slidesPerView={1}
                spaceBetween={10}
                freeMode={true}
                navigation={true}
                breakpoints={{
                    480: {
                        slidesPerView: 2,
                        spaceBetween: 20,
                    },
                    640: {
                        slidesPerView: 3,
                        spaceBetween: 20,
                    },
                    768: {
                        slidesPerView: 6,
                        spaceBetween: 40,
                    },
                    1024: {
                        slidesPerView: 8,
                        spaceBetween: 10,
                    },
                }}
                modules={[FreeMode, Navigation]}
                className="mySwiper w-full h-full"
            >
                {teachers.map((item) => (
                    <SwiperSlide key={item.id}>
                        <div className="w-full flex flex-col gap-4 items-center mt-14">
                            <img
                                className="w-20 h-20 rounded-full object-cover object-center"
                                src={`${baseURL}/${item.picture}`}
                                alt={`Teacher ${item.name}`}
                            />
                            <span>{item.name}</span>
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>
        </>
    );
};

export default TeacherSlider;

import React, { useState } from "react";
import Electric from "../../components/svgs/Electric";

import "./MainSection.css";

import Button from "../../components/ui/Button";
import auth from "../../services/authServices";
// import FlexRowReverse from "../../components/ui/FlexRowReverse";
import Container from "../../components/ui/Container";
import NewProfile from "../../assets/Updated-imagery/New-Profile-hero.svg";
import starsbg from "../../assets/starsbg.png";
import bgbottomleft from "../../assets/bgbottomleft.svg";
import TeacherSlider from "./TeacherSlider";
import BookIcon from "../../assets/Vip-imagery/Book.png";
import VariantButton from "../../components/ui/Buttons/VariantButton";
import { ReactComponent as HeroImage } from "../../assets/Vip-imagery/HeroImg.svg";
import "./Heroimage.css";

const MainSection = ({ title = false }) => {
    const token = auth.getToken();

    // const buttonColorClass = token ? {} : { color: "rose" };

    return (
        <>
            <section className="md:h-screen min-h-screen relative overflow-hidden flex md:items-center md:justify-center md:mb-[10rem] home-main_section">
                <Container className="h-auto relative z-10  ">
                    <div className="w-full flex md:flex-row-reverse flex-col h-full justify-evenly md:h-screen space-y-20 space-y-reverse md:space-y-0 relative z-10 items-center">
                        <div className="md:basis-1/2 basis-full h-full flex-center-both pt-20 md:pt-0">
                            <div className="w-full h-full flex-center-both">
                                {/* <img
                                    src={NewProfile}
                                    alt={"profile-updated-svg"}
                                    className="dark:opacity-90 smooth w-full"
                                /> */}
                                <HeroImage className="dark:opacity-90 smooth w-full" />
                            </div>
                        </div>
                        <div className="  md:basis-1/2 basis-full h-full flex items-center ">
                            <div className="flex-col md:space-y-12 space-y-6 text-center lg:text-right">
                                <div className="flex flex-col space-y-8">
                                    <div className="flex justify-center lg:justify-start">
                                        <h1 className="font-w-bold smooth relative font-expo text-4xl md:text-5xl xl:text-6xl">
                                            <div className="min-w-[346px] min-h-[150px] md:min-h-[348.86px] outline outline-[1px] smooth outline-Lemon-500 dark:outline-Lemon-200 absolute -right-[350px] lg:-right-[500px] -z-10 top-24" />
                                            <div className="min-w-[346px] min-h-[150px] md:min-h-[348.86px] outline outline-[1px] smooth outline-Lemon-500 dark:outline-Lemon-200 absolute -right-[300px] lg:-right-[450px] top-36 -z-10" />
                                            <div className="relative z-10 clr-text-primary smooth ">
                                                منصة
                                                {"   "}
                                                <span className="text-blueVip-500 ">سنتر VIP</span>
                                            </div>
                                        </h1>
                                    </div>
                                    <h2 className="font-h2 font-expo ">
                                        أكبر صرح تعليمى متطور بكل الخدمات
                                        <br />
                                        مع أقوى نخبة مدرسين في كل المواد{" "}
                                    </h2>
                                </div>

                                <VariantButton
                                    color="Lemon"
                                    icon={BookIcon}
                                    ButtonType="Retro"
                                    className="scale gap-2 flex-row-reverse font-bold text-Lemon-950"
                                    element="Link"
                                    to={`${token ? "/me/user/courses" : "/register"}`}
                                >
                                    {token ? <span>كورساتي </span> : <span>أشترك دلوقتي</span>}
                                </VariantButton>

                                {title ? <div className="font-h2">{title}</div> : ""}
                            </div>
                        </div>
                    </div>
                    {/* <div className="w-full flex flex-col justify-center h-1/3">
                        <h2 className="font-h2 font-w-bold text-center">
                            <span>مُدرسين</span>{" "}
                            <span className="font-w-bold text-sky-500 bg-box relative">
                                بسطتهالك !
                            </span>
                        </h2>
                        <TeacherSlider />
                    </div> */}
                </Container>
            </section>
        </>
    );
};

export default MainSection;

import React from "react";

import { ScrollingProvider } from "../context/ScrollingContext";

import MainSection from "../sections/home/MainSection";
// import AboutSection from "../sections/home/AboutSection";
import SubjectsSection from "../sections/home/CoursesSection/SubjectsSection";
import auth from "../services/authServices";
import LoggedInHome from "../sections/home/LoggedInHome";
import TeacherSection from "../sections/home/TeacherSection";
import FeatureSection from "../sections/home/FeatureSection";
import CtaStudent from "../sections/home/CtaStudent";
import CtaTeachers from "../sections/home/CtaTeachers";
import BookSection from "../sections/home/BookSection";

const Home = () => {
    const token = auth.getToken();
    return (
        <>
            <ScrollingProvider>
                {token ? (
                    <>
                        <LoggedInHome />
                    </>
                ) : (
                    <>
                        <MainSection />
                        <FeatureSection />
                        <TeacherSection />
                    </>
                )}
            </ScrollingProvider>

            <SubjectsSection />
            <BookSection />
        </>
    );
};

export default Home;

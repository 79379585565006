import React from "react";
import Container from "../../components/ui/Container";
import Heading from "../../components/NewUi/heading/Heading";
import { isEmptyObject } from "jquery";
import VariantButton from "../../components/ui/Buttons/VariantButton";
import BookIcon from "../../assets/Vip-imagery/Book.png";
import modal from "../../services/modalServices";

const BookSection = () => {
    const handleBooks = () => {
        modal.message({
            title: "سيتم توافر هذه الخدمه قريباً",
            text: `هنوفرلك كل الكتب قريب ان شاءالله`,
            icon: "warning",
        });
    };
    return (
        <Container className="my-[10rem] flex relative flex-col space-y-20 items-center justify-center overflow-y-hidden">
            <div className="flex flex-col space-y-20 items-center">
                <div className="flex-center-both">
                    <Heading title="وكُتبنا ؟" subtitle="يلا أحجز ..؟" />
                </div>
                <p className="text-center w-full text-lg md:text-xl md:max-w-[500px]">
                    وفرنالك في منصتنا معظم كٌـــتب مُـدرسين السنتر الي ممكن تحناجها أحجزها من عندنا
                    واستلمها بالشكل الي يريحك ناقصك اي تاني ؟
                </p>
            </div>

            <VariantButton
                color="Lemon"
                icon={BookIcon}
                ButtonType="Retro"
                className="scale gap-2 flex-row-reverse text-black font-bold"
                element="button"
                onClick={handleBooks}
            >
                <span>شوف الكتب كلها من هنا </span>
            </VariantButton>

            <p className="text-stroke w-screen text-transparent text-[2rem] md:text-[8rem] font-w-bold font-expo absolute -bottom-2 md:-bottom-5 right-5 md:right-1/4 -z-10 opacity-40 ">
                يلاااا .... VIP ... VIP
            </p>
        </Container>
    );
};

export default BookSection;

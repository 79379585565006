import React from "react";
// import { Link } from "react-router-dom";
// import { Icon } from "@iconify/react";

const FooterLink = ({ icon, className, link = "/" }) => {
    return (
        <a
            href={link}
            className={`relative w-14 md:w-16 h-14 md:h-16 flex justify-between rounded-full font-h1 hover-shadow smooth ${className}`}
        >
            {/* <span>{icon}</span> */}
            <span className="relative">{icon}</span>
        </a>
    );
};

export default FooterLink;

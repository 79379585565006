import React from "react";
import "./heading.css";

function Heading({ title, subtitle }) {
    return (
        <div className="flex relative flex-col min-w-[250px] gap-5 justify-center items-center w-full max-w h-fit">
            <div className=" bg-primary-container smooth min-w-[350px] clr-text-primary font-expo  flex-col space-y-5 flex-center-both  px-5 py-5">
                <h1 className="text-4xl font-w-bold sm:text-6xl">{title} </h1>
                <span className="text-blueVip-500 text-2xl">{subtitle}</span>
            </div>
            <div className="absolute -z-10 -top-5 -bottom-5 right-2 left-2  p-4 border border-solid border-blueVip-500">
                <div className="border border-solid h-full border-blueVip-500" />
            </div>
        </div>
    );
}

export default Heading;

import { Icon } from "@iconify/react";
import React, { useEffect, useState } from "react";

import http from "../../../services/httpServices";

import Container from "../../../components/ui/Container";
import LoadingIcon from "../../../components/ui/LoadingIcon";
import SideTitle from "../../../components/ui/SideTitle";
import CourseCard from "../../../components/ui/CourseCard";
import auth from "../../../services/authServices";

const Categorie = ({
    titleFirst = false,
    titleLast,
    api,
    className = "",
    grid = "normal",
    noCoursesPlaceholder = "سيتم اضافة الكورسات قريبًا",
    coursesList = false,
    isPrepaid = false,
    onPrepaidClick = null,
    isToAssignCourses = null,
    onAssignCourseClick = false,
    sortColumn = null,
    sortDir = "asc",
}) => {
    const [courses, setCourses] = useState([]);

    if (!className.includes("space-x")) {
        if (grid === "normal") {
            className += ` grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-10`;
        } else {
            className += ` grid-cols-1 lg:grid-cols-2 gap-5`;
        }
    }

    const [placeholder, setPlaceholder] = useState(
        <div className="font-h2 col-span-1 md:col-span-2 lg:col-span-3 py-20">
            <span className="flex-center-both space-x-3 space-x-reverse">
                <LoadingIcon className={"font-h1 text-teal-600 dark:text-teal-400"} />
                <span>يتم الآن تحميل الكورسات</span>
            </span>
        </div>
    );
    const getCourses = async () => {
        try {
            const token = auth.getToken();
            const config = auth.getAuthConfig(token);
            const { data } = await http.get(api, config);

            if (sortColumn) {
                function compare(a, b) {
                    if (a[sortColumn] < b[sortColumn]) {
                        return sortDir === "asc" ? -1 : 1;
                    }
                    if (a[sortColumn] > b[sortColumn]) {
                        return sortDir === "asc" ? 1 : -1;
                    }
                    return 0;
                }

                data.sort(compare);
                // data;
            }

            setCourses([]);
            setCourses(data);
            setPlaceholder(
                <div className="font-h2 col-span-1 md:col-span-2 lg:col-span-3 py-20">
                    <span className="flex-center-both space-x-3 space-x-reverse">
                        <span className="font-h1 flex-center-both text-rose-600 dark:text-rose-400">
                            <Icon icon="carbon:data-error" />
                        </span>
                        <span>{noCoursesPlaceholder}</span>
                    </span>
                </div>
            );
        } catch (error) {
            setPlaceholder(
                <div className="font-h2 col-span-1 md:col-span-2 lg:col-span-3 py-20">
                    <span className="flex-center-both space-x-3 space-x-reverse">
                        <span className="font-h1 flex-center-both text-rose-600 dark:text-rose-400">
                            <Icon icon="carbon:data-error" />
                        </span>
                        <span>{noCoursesPlaceholder}</span>
                    </span>
                </div>
            );
        }
    };

    useEffect(() => {
        if (coursesList) {
            setCourses(coursesList);
            setPlaceholder(
                <div className="font-h2 col-span-1 md:col-span-2 lg:col-span-3 py-20">
                    <span className="flex-center-both space-x-3 space-x-reverse">
                        <span className="font-h1 flex-center-both text-rose-600 dark:text-rose-400">
                            <Icon icon="carbon:data-error" />
                        </span>
                        <span>{noCoursesPlaceholder}</span>
                    </span>
                </div>
            );
        } else {
            if (api) {
                getCourses();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [coursesList, api]);
    return (
        <Container>
            {titleFirst ? <SideTitle first={titleFirst} last={titleLast} /> : ""}

            <div
                className={`g-teal-400 smooth clr-text-primary drk:bg-teal-800 bg-opacity-50 dark:bg-opacity-50 grid ${className}`}
            >
                {courses.length < 1 && placeholder}
                {courses.length > 0 &&
                    courses.map((course) => {
                        return (
                            <CourseCard
                                key={course.id}
                                {...course}
                                isToAssignCourses={isToAssignCourses}
                                onAssignCourseClick={onAssignCourseClick}
                                isPrepaid={isPrepaid}
                                onPrepaidClick={onPrepaidClick}
                            />
                        );
                    })}
            </div>
        </Container>
    );
};

export default Categorie;

import React, { useContext, useEffect, useState } from "react";
import InputField from "../../../components/form/elements/InputField";
import { handleInputChange } from "../../../services/formServices";
import { isMultiYear } from "../../../services/defaultSettings";
import { years } from "../../../services/yearSevices";
import Container from "../../../components/ui/Container";
import auth from "../../../services/authServices";
import http from "../../../services/httpServices";
import AuthContext from "../../../context/AuthContext";
import { isEmptyObject } from "jquery";
import SubjectCard from "../../../components/NewUi/subjectCard/SubjectCard";
import Heading from "../../../components/NewUi/heading/Heading";
import TeacherLine from "../../../components/NewUi/teacherHorizontal/TeacherLine";
import Card from "../../../components/NewUi/featureCard/Card";
import RetroCardSection from "../../../components/ui/RetroCardSection";
// import { ScrollingProvider } from "../../../context/ScrollingContext";
// import SectionHead from "../../../components/ui/SectionHead";
// import SubjectsGrid from "./SubjectsGrid";
// import TeachersGrid from "./TeachersGrid";
// import CoursesGrid from "./CoursesGrid";
// import SideTitle from "../../../components/ui/SideTitle";
// import Categorie from "./Categorie";
// import { values } from "lodash";

const SubjectsSection = () => {
    const [filterData, setFilterData] = useState({
        year: 3,
        department_id: 0,
    });
    const [departments, setDepartments] = useState([]);
    const [categorieApi, setCategorieApi] = useState("");

    const { user } = useContext(AuthContext);
    const [first, setFirst] = useState([]);
    const [second, setSecond] = useState([]);
    const [third, setThird] = useState([]);
    const toUseYears = years.filter((value, index) => index < 3);
    const [subjectData, setSubjectData] = useState({
        first: [],
        sec: [],
        third: [],
        fourth: [],
        fifth: [],
        sixth: [],
    });

    const getSubjects = async () => {
        const { data } = await http.get(`api/with_year_id/subjects/options`);
        console.log("API Response:", data);

        const years = ["1", "2", "3", "4", "5", "6"];

        const newSubjectData = years.reduce((acc, year) => {
            acc[year] = data
                .filter((subject) => subject.year === year)
                .map((item) => [item.text, item.value]);
            return acc;
        }, {});
        // console.log("Filtered and Mapped Data newSubjectData:", newSubjectData["1"]);

        setSubjectData({
            first: newSubjectData["1"],
            sec: newSubjectData["2"],
            third: newSubjectData["3"],
            fourth: newSubjectData["4"],
            fifth: newSubjectData["5"],
            sixth: newSubjectData["6"],
        });

        // console.log("Updated subjectData:", subjectData);
    };

    const getDepartments = async () => {
        const token = auth.getToken();
        const config = auth.getAuthConfig(token);
        const { data } = await http.get(`api/years/${filterData.year}/departments/options`, config);
        // console.log(data);
        // console.log(filterData)
        setDepartments([]);
        if (data.length < 1) {
            setFilterData({ ...filterData, department_id: 0 });
        } else {
            setFilterData({ ...filterData, department_id: data[0]["value"] });
        }
        setDepartments(data);
        console.log(filterData.department_id);
    };

    useEffect(() => {
        getSubjects();
        getDepartments();
    }, [filterData.year]);

    useEffect(() => {
        getSubjects();
    }, []);

    useEffect(() => {
        if (!isEmptyObject(user) && user.year && user.year != filterData.year) {
            setFilterData({
                ...filterData,
                year: user.year,
            });
        }
    }, [user]);

    useEffect(() => {
        if (filterData.department_id > 0) {
            setCategorieApi(`/api/departments/${filterData.department_id}/courses`);
        }
    }, [filterData.department_id]);

    // useEffect(() => {
    //     console.log("Updated subjectData:", subjectData);
    // }, [subjectData]);

    return (
        <div className=" mb-20 mt-10  overflow-hidden" id="courses">
            {/* <ScrollingProvider>
                <SectionHead title={"محتوى المنصة"} /> 
            </ScrollingProvider> */}
            <div className="">
                <Container className="flex relative flex-col space-y-10 items-center justify-center">
                    <div
                        className={`min-w-[346px] min-h-[150px] md:min-h-[348.86px] outline outline-[1px] outline-Lemon-500 opacity-30 dark:opacity-20 dark:outline-Lemon-200 absolute -right-4  bottom-0 -z-10 `}
                    />
                    <div
                        className={`min-w-[346px] min-h-[150px] md:min-h-[348.86px] outline outline-[1px] outline-Lemon-500 opacity-30 dark:opacity-20 dark:outline-Lemon-200 absolute -right-8 -bottom-10 -z-10 `}
                    />
                    <div className="flex flex-col space-y-20 items-center">
                        <div className="flex-center-both">
                            <Heading title="مواد منصتك" subtitle="خبرة بقي ..؟" />
                        </div>
                        <p className="text-center w-full text-lg md:text-xl md:max-w-[500px]">
                            بطريقة سلسه جداً تقدر تاخد بصه علي الماده الي انت محتاجها هتلاقي جوه
                            المدرسين المتخصصين بالمادة الي اختارتها او كورسات السنتر نفسه
                        </p>
                    </div>
                    <RetroCardSection isSubject subjectData={subjectData} />
                </Container>

                <Container className="my-[10rem]">
                    <Container className="flex flex-col space-y-10 items-center justify-center">
                        <div className="flex flex-col space-y-20 items-center">
                            <div className="flex-center-both">
                                <Heading title="كورساتنا ؟" subtitle="عايز مين ..؟" />
                            </div>
                            <p className="text-center w-full text-lg md:text-xl md:max-w-[500px]">
                                من خلال أختيارك سنتك الدراسية والشعبة برضو هيظهرلك كل مدرسينك علي
                                حسب اختيارك دوس عليه وخش أشترك في الكورسات الي تحبها
                            </p>
                        </div>
                    </Container>

                    <div
                        className={
                            isEmptyObject(user) || !user.year
                                ? "flex-center-both"
                                : "flex items-center justify-start"
                        }
                    >
                        <div className="flex items-center justify-between md:flex-row flex-col md:space-x-20 md:space-x-reverse space-y-4 md:space-y-0">
                            <div className=" px-10 py-3 rounded-md  border border-solid border-gray-400 smooth clr-text-primary">
                                {isMultiYear && (isEmptyObject(user) || !user.year) ? (
                                    <InputField
                                        onChange={handleInputChange}
                                        data={filterData}
                                        setData={setFilterData}
                                        type="select"
                                        options={toUseYears}
                                        className="min-w-[250px]"
                                        id="year"
                                        placeholder="اختر الصف الدراسي"
                                        // errors={errors}
                                    />
                                ) : (
                                    ""
                                )}
                            </div>
                            <div className=" px-10 py-3 rounded-md  border border-solid border-gray-400 smooth clr-text-primary">
                                <InputField
                                    onChange={handleInputChange}
                                    data={filterData}
                                    setData={setFilterData}
                                    type="select"
                                    className="min-w-[250px]"
                                    options={departments}
                                    id="department_id"
                                    placeholder="اختر الشعبة"
                                />
                            </div>
                        </div>
                    </div>
                </Container>
                <div>
                    {filterData.department_id === 0 ? (
                        <Container>
                            <div className="flex-center-both">
                                <div className="border-2 rounded-md border-yellow-300 dark:border-yellow-500 p-10 bg-yellow-100 dark:opacity-5 smooth clr-text-primary">
                                    اختر الشعبة الدراسية اولًا لإختيار المادة !
                                </div>
                            </div>
                        </Container>
                    ) : (
                        <>
                            <div className="relative">
                                {" "}
                                <TeacherLine department_id={filterData.department_id} />{" "}
                                <div
                                    className={`min-w-[346px] smooth min-h-[150px] md:min-h-[348.86px] outline outline-[1px] outline-Lemon-500 opacity-30 dark:opacity-20 dark:outline-Lemon-200 absolute -left-4  bottom-0 -z-10 `}
                                />
                                <div
                                    className={`min-w-[346px] smooth min-h-[150px] md:min-h-[348.86px] outline outline-[1px] outline-Lemon-500 opacity-30 dark:opacity-20 dark:outline-Lemon-200 absolute -left-8 -bottom-10 -z-10 `}
                                />
                            </div>
                            {/* <SubjectsGrid department_id={filterData.department_id} /> */}
                            {/* <TeachersGrid department_id={filterData.department_id} /> */}
                            {/* <Categorie
                                titleFirst="كورسات"
                                titleLast="الشعبة"
                                noCoursesPlaceholder={"سيتم اضافة الكورسات داخل هذه الشبعة قريبًا"}
                                api={categorieApi}
                            /> */}
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default SubjectsSection;

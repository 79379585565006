import React from "react";
import Teachers from "../../components/NewUi/horizontalAnimation/Teachers";
import Heading from "../../components/NewUi/heading/Heading";
import Container from "../../components/ui/Container";
import Button from "../../components/ui/Button";
import auth from "../../services/authServices";
import Star from "../../assets/Vip-imagery/star.png";
import VariantButton from "../../components/ui/Buttons/VariantButton";
import ConstructionDecore from "../../components/ui/ConstructionDecore";
const TeacherSection = () => {
    const token = auth.getToken();

    return (
        <section className="min-h-[80vh] relative my-10 overflow-hidden">
            <Container className="h-full  z-10 pt-32 md:pt-10 pb-20 md:pb-0  flex flex-col items-center justify-center space-y-20">
                <div className="flex relative flex-col items-center justify-center space-y-20">
                    <div className="flex flex-col items-center justify-center h-fit gap-20">
                        <div className="mx-auto">
                            <Heading title="مين معاك ؟" subtitle="شوف مٌدرسينك ..؟" />
                        </div>
                        <p className="text-center w-full text-lg md:text-xl md:max-w-[600px]">
                            بسهولة جدا بقيت تقدر توصل لأكفأ وأجمد المدرسين اونلاين عشان يساعدوك
                            ويجاوبوك على كل اسئلتك الخاصة بالمنهج الدراسي.
                        </p>
                    </div>
                    <VariantButton
                        color="Lemon"
                        element="Link"
                        ButtonType="Retro"
                        className="scale gap-4 text-Lemon-950 font-w-bold "
                        icon={Star}
                        to={`${token ? "/me/user/courses" : "/register"}`}
                    >
                        {token ? <span>استمتع بالشرح </span> : <span>متفوتش فرصتك</span>}
                    </VariantButton>
                    {/* <div className="-ml-20">
                        <ConstructionDecore className={""} isRight={false} />
                    </div> */}
                    <div
                        className={`min-w-[346px] min-h-[150px] md:min-h-[300px] outline outline-[1px] outline-Lemon-500 smooth opacity-20 dark:opacity-40 dark:outline-Lemon-200 absolute -left-20 lg:-left-[100%]  top-2/3 -z-10 `}
                    />
                    <div
                        className={`min-w-[346px] min-h-[150px] md:min-h-[300px] outline outline-[1px] outline-Lemon-500 smooth opacity-20 dark:opacity-40 dark:outline-Lemon-200 absolute  -left-16 lg:-left-[110%] top-3/4 -z-10 `}
                    />
                </div>
                <div className="">
                    <Teachers />
                </div>
            </Container>
        </section>
    );
};

export default TeacherSection;

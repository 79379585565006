import { Icon } from "@iconify/react";
import React, { useEffect, useState } from "react";

import FooterLink from "./components/FooterLink";
import { Facebook, Youtube, Tiktok, instagram } from "./Icons";
import { useLocation } from "react-router-dom";
import { adminPath, teachersubadminPath } from "../../services/defaultSettings";
import CenterIcon from "../../components/ui/CenterIcon";
import { ReactComponent as Logo } from "../../assets/Vip-imagery/Logo.svg";
const Footer = () => {
    const location = useLocation();

    const [className, setClassName] = useState("");

    useEffect(() => {
        if (
            location.pathname.includes(adminPath) ||
            location.pathname.includes(teachersubadminPath)
        ) {
            setClassName(" !hidden");
        } else {
            setClassName("");
        }
    }, [location.pathname]);
    return (
        <div
            className={`footer bg-[#2C3A66] overflow-hidden relative py-20 clr-white flex-center-both flex-col space-y-6 w-full ${className} gap-6`}
        >
            <div className="w-[200px] md:w-[260px] h-auto flex justify-center">
                <Logo className="fill-blueVip-300" />
            </div>
            <div className="flex-center-both space-x-2 space-x-reverse z-10 text-2xl smooth rounded-md px-5 py-4 saturate-50">
                <p className="">متخصصة للطلاب الساعين للتفوق</p>
            </div>
            <div className="relative w-screen">
                <div className="relative z-10 flex items-center justify-center space-x-5 space-x-reverse gap-4 ">
                    <FooterLink link="https://www.facebook.com/vipcenter100" icon={Facebook} />
                    <FooterLink
                        link="https://www.instagram.com/vip.edu.center"
                        // className={`bg-gradient-to-r from-orange-500 to-pink-500 `}
                        icon={instagram}
                    />
                    <FooterLink
                        link=""
                        // className={`bg-gradient-to-r from-rose-500 to-red-500 `}
                        icon={Tiktok}
                    />
                    <FooterLink
                        link=""
                        // className={`bg-gradient-to-r from-rose-500 to-red-500 `}
                        icon={Youtube}
                    />
                </div>
                <p className="absolute text-stroke w-screen right-1/4 text-transparent text-[3rem] md:text-[8rem] font-w-bold font-expo  -bottom-10 md:-bottom-20  opacity-40 ">
                    يلاااا .... VIP ... VIP
                </p>
            </div>
            {/* <div className="h-1 bg-slate-800 rounded-md w-2/3 sm:w-1/3 "></div> */}
            <div className="Footer__wrapper-button mt-9 lg:mt-0">
                <div className="en textx text-center font-com space-x-2 opacity-90 px-5 flex flex-wrap flex-center-both">
                    <span className="font-w-bold space-x-1">
                        <span className="text-blueVip-200">&#60;</span>
                        <span className="text-blueVip-400">Developed By</span>
                        <span className="text-blueVip-200">&#62;</span>
                    </span>
                    <span>
                        <a
                            href="https://www.facebook.com/Om4rS4Ieh/"
                            className="text-Lemon-300 font-w-bold  hover-shadow smooth px-2 py-2 rounded-md"
                        >
                            Omar
                        </a>
                        <span>,</span>
                        <a
                            href="https://www.facebook.com/emad.sharf.16"
                            className="text-Lemon-300 font-bold hover-shadow smooth px-3 py-1 rounded-md"
                        >
                            Emad
                        </a>
                    </span>
                    <span className="font-w-bold space-x-1">
                        <span className="text-blueVip-200">&#60;</span>
                        <span className="text-blueVip-400">
                            All Copy Rights Reserved @{new Date().getFullYear()}
                        </span>
                        <span className="text-blueVip-200">&#62;</span>
                    </span>
                </div>
            </div>
            {/* <div className="text"></div> */}
        </div>
    );
};

export default Footer;

import React, { useContext, useEffect, useState } from "react";
import InputField from "../components/form/elements/InputField";
import { handleFormErrors, handleInputChange } from "../services/formServices";
import InputIcon from "../components/form/elements/InputIcon";
import Button from "../components/ui/Button";
import http from "../services/httpServices";
import a from "../services/analyticsServices";
import auth from "../services/authServices";
import AuthContext from "../context/AuthContext";
import modal from "../services/modalServices";
import { useLocation, useNavigate } from "react-router-dom";
import LoadingIcon from "../components/ui/LoadingIcon";
import { getUnit } from "../utils/ar";
import CenterIcon from "../components/ui/CenterIcon";

const ForgetPasswordComponent = ({
    showForgetPassword,
    setShowForgetPassword,
    isConfirmedShowPasswordPhone,
    data,
    setData,
    errors,
    setErrors,
}) => {
    // const [toChangePhone, setToChangePhone] = useState(false);
    // const [isPhoneConfirmed, setIsPhoneConfirmed] = useState(false);

    const [isLoading, setIsLoading] = useState(false);

    const [isOtpSent, setIsOtpSent] = useState(false);

    const [sendNewOtpTime, setSendNewOtpTime] = useState(0);
    // const [isOtpLoading, setIsOtpLoading] = useState(true);
    const [isError, setIsError] = useState(false);

    const { setUser: authUser } = useContext(AuthContext);

    const navigate = useNavigate();
    const { state } = useLocation();
    const redirect = (certainPath = false) => {
        if (certainPath) {
            navigate(certainPath);
        } else {
            if (!state) {
                navigate("/home");
            } else {
                navigate(state.prevPath);
            }
        }
    };

    const handleVerifyOtp = async () => {
        setIsLoading(true);
        await http.get("/sanctum/csrf-cookie");
        const visitorVisitId = a.getVisitorVisit();
        const toSendUser = { ...data, visitor_visit_id: visitorVisitId };
        toSendUser["phone"] = parseInt(data["phone"]);
        toSendUser["otp"] = parseInt(data["otp"]);
        try {
            const result = await http.post("api/auth/verify_otp_with_reset_password", toSendUser);
            auth.authenticate(result.data.token, result.data.user);
            authUser({
                ...result.data.user,
            });
            setIsLoading(false);
            modal.message({
                title: "تم تسجيل الدخول بنجاح !",
                text: "اضغط حسنًا للإستمرار",
                callback: redirect,
            });
        } catch ({ response }) {
            handleFormErrors(response, setIsLoading, setErrors);
        }
    };
    const createOtp = async () => {
        setIsError(false);
        setIsLoading(true);
        await http.get("/sanctum/csrf-cookie");
        const visitorVisitId = a.getVisitorVisit();
        const toSendUser = { ...data, visitor_visit_id: visitorVisitId };
        toSendUser["phone_to_reset"] = parseInt(data["phone_to_reset"]);
        try {
            const { data } = await http.post("api/auth/forget_password_otp", toSendUser);

            setIsLoading(false);
            setSendNewOtpTime(90);
            setIsOtpSent(true);
        } catch ({ response }) {
            if (!response || response.status != 422) {
                setIsError(true);
            }
            handleFormErrors(response, setIsLoading, setErrors);
            // setIsOtpLoading(true);
            // handleFormErrors(response, setIsLoading, setErrors);
        }
    };
    // useEffect(() => {
    //     if (!showChangePhone || isPhoneConfirmed) {
    //         createOtp();
    //         // setIsError(false);
    //         // setIsOtpLoading(false);
    //     }
    // }, [showChangePhone, isPhoneConfirmed]);
    useEffect(() => {
        //
        if (data.phone.length === 11 && !data.phone_to_reset) {
            setData({ ...data, phone_to_reset: data.phone });
        }
    }, [showForgetPassword]);
    return (
        <div
            className={`fixed w-full h-full inset-0 bg-slate-900 dark:bg-slate-900 smooth ${
                showForgetPassword
                    ? "bg-opacity-50 dark:bg-opacity-50 z-30"
                    : "bg-opacity-0 dark:opacity-0 z-0"
            }`}
        >
            <div
                className="absolute w-full h-full inset-0"
                onClick={() => setShowForgetPassword(false)}
            ></div>
            <div
                className={`absolute ${
                    showForgetPassword ? "top-1/2" : "-top-1/2"
                } right-1/2 translate-x-1/2 -translate-y-1/2 bg-slate-100 dark:bg-slate-900 border-4 border-slate-300 dark:border-slate-800 rounded-md shadow-large py-10 md:px-10 px-4 smooth md:w-1/2 sm:w-2/3 w-4/5 clr-text-primary`}
            >
                {
                    <div className="flex flex-col space-y-12">
                        <div className="space-y-6">
                            <div>
                                <div className="font-w-bold font-h3">
                                    {isOtpSent
                                        ? "اكتب الكود المرسل الخاص بك"
                                        : "اكتب رقم الهاتف الخاص بك"}
                                </div>
                                <div className="">
                                    {isOtpSent
                                        ? `
                                        اكتب الكود المرسل على رقم الهاتف الخاص بك و كلمة السر الجديدة`
                                        : `سيتم ارسال كود في رسالة على رقم الهاتف الخاص بك للتأكد من هوية
                                        حسابك ثم بإمكانك تغيير كلمة السر لكلمة سر جديدة`}
                                </div>
                            </div>
                        </div>

                        <div className="space-y-12">
                            {isError ? (
                                <>
                                    <div className="flex-center-both flex-row space-x-2 space-x-reverse">
                                        {isError ? (
                                            <>
                                                <span className="font-h2 text-rose-500">
                                                    <CenterIcon icon="ic:twotone-error" />
                                                </span>
                                                <span>حدث خطأ</span>
                                            </>
                                        ) : (
                                            <>
                                                <span className="font-h2 text-rose-500">
                                                    <LoadingIcon />
                                                </span>
                                                <span>يتم ارسال الكود الآن</span>{" "}
                                            </>
                                        )}
                                    </div>
                                    {isError ? (
                                        <Button color="rose" onClick={createOtp}>
                                            اعادة المحاولة
                                        </Button>
                                    ) : (
                                        ""
                                    )}
                                </>
                            ) : (
                                <>
                                    {isOtpSent ? (
                                        <>
                                            <InputField
                                                onChange={handleInputChange}
                                                data={data}
                                                setData={setData}
                                                errors={errors}
                                                id="otp"
                                                placeholder="الكود الموجود في الرسالة"
                                                icon={<InputIcon icon="uil:comment-verify" />}
                                            />
                                            <InputField
                                                onChange={handleInputChange}
                                                data={data}
                                                setData={setData}
                                                errors={errors}
                                                id="new_password"
                                                type="password"
                                                placeholder="كلمة السر الجديدة"
                                                icon={<InputIcon icon="ri:lock-password-fill" />}
                                            />
                                            <InputField
                                                onChange={handleInputChange}
                                                data={data}
                                                setData={setData}
                                                errors={errors}
                                                id="new_password_confirmation"
                                                type="password"
                                                placeholder="تأكيد كلمة السر الجديدة"
                                                icon={<InputIcon icon="ri:lock-password-fill" />}
                                            />
                                            <Button
                                                color="yellow"
                                                onClick={handleVerifyOtp}
                                                isLoading={isLoading}
                                            >
                                                تغيير كلمة المرور !
                                            </Button>
                                        </>
                                    ) : (
                                        <>
                                            {isConfirmedShowPasswordPhone ? (
                                                <div>
                                                    <div className="font-w-bold">رقم الهاتف :</div>
                                                    <div className="font-h3">{data.phone}</div>
                                                </div>
                                            ) : (
                                                <InputField
                                                    onChange={handleInputChange}
                                                    data={data}
                                                    setData={setData}
                                                    errors={errors}
                                                    id="phone_to_reset"
                                                    placeholder="رقم الهاتف"
                                                    icon={
                                                        <InputIcon icon="ant-design:phone-filled" />
                                                    }
                                                />
                                            )}
                                            <Button
                                                color="yellow"
                                                onClick={createOtp}
                                                isLoading={isLoading}
                                            >
                                                ارسال الكود
                                            </Button>
                                        </>
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                }
                {
                    // showChangePhone && !isPhoneConfirmed ? (
                    //     <div className="flex flex-col space-y-12">
                    //         <div className="space-y-6">
                    //             <div>
                    //                 <div>هل انت متأكد من الرقم الهاتف الخاص بك؟</div>
                    //                 <div>
                    //                     سيتم ارسال كود في رسالة على رقم الهاتف الخاص بك لتأكيد تسجيل
                    //                     الدخول
                    //                 </div>
                    //             </div>
                    //             <div>رقم الهاتف المسجل :</div>
                    //             <div className="font-h2">{data.phone}</div>
                    //         </div>
                    //         {!toChangePhone ? (
                    //             <Button color="blue" onClick={() => setToChangePhone(true)}>
                    //                 تغيير رقم الهاتف
                    //             </Button>
                    //         ) : (
                    //             ""
                    //         )}
                    //         {toChangePhone ? (
                    //             <div className="space-y-12">
                    //                 <InputField
                    //                     onChange={handleInputChange}
                    //                     data={data}
                    //                     setData={setData}
                    //                     errors={errors}
                    //                     id="new_phone"
                    //                     placeholder="رقم الهاتف الجديد"
                    //                     icon={<InputIcon icon="ant-design:phone-filled" />}
                    //                 />
                    //                 <Button
                    //                     color="yellow"
                    //                     onClick={handleChangePhone}
                    //                     isLoading={isLoading}
                    //                 >
                    //                     تغيير رقم الهاتف
                    //                 </Button>
                    //             </div>
                    //         ) : (
                    //             ""
                    //         )}
                    //         {!toChangePhone ? (
                    //             <Button
                    //                 color="rose"
                    //                 onClick={() => setIsPhoneConfirmed(true)}
                    //                 isLoading={isLoading}
                    //             >
                    //                 تأكيد رقم الهاتف !
                    //             </Button>
                    //         ) : (
                    //             ""
                    //         )}
                    //     </div>
                    // ) : (
                    //     <div className="flex flex-col space-y-12">
                    //         <div className="space-y-6">
                    //             <div>
                    //                 <div>تم ارسال رسالة نصية للرقم الخاص بك</div>
                    //                 <div>
                    //                     يرجى كتابة الكود الموجود داخل الرسالة هنا لتأكيد تسجيل الدخول!
                    //                 </div>
                    //             </div>
                    //         </div>
                    //         <div className="space-y-12">
                    //             {isOtpLoading ? (
                    //                 <>
                    //                     <div className="flex-center-both flex-col space-y-2">
                    //                         <div className="flex-center-both flex-row space-x-2 space-x-reverse">
                    //                             {isError ? (
                    //                                 <>
                    //                                     <span className="font-h2 text-rose-500">
                    //                                         <CenterIcon icon="ic:twotone-error" />
                    //                                     </span>
                    //                                     <span>حدث خطأ</span>
                    //                                 </>
                    //                             ) : (
                    //                                 <>
                    //                                     <span className="font-h2 text-rose-500">
                    //                                         <LoadingIcon />
                    //                                     </span>
                    //                                     <span>يتم ارسال الكود الآن</span>{" "}
                    //                                 </>
                    //                             )}
                    //                         </div>
                    //                         {isError ? (
                    //                             <Button color="rose" onClick={createOtp}>
                    //                                 اعادة المحاولة
                    //                             </Button>
                    //                         ) : (
                    //                             ""
                    //                         )}
                    //                     </div>
                    //                 </>
                    //             ) : (
                    //                 <InputField
                    //                     onChange={handleInputChange}
                    //                     data={data}
                    //                     setData={setData}
                    //                     errors={errors}
                    //                     id="otp"
                    //                     placeholder="الكود الموجود في الرسالة"
                    //                     icon={<InputIcon icon="uil:comment-verify" />}
                    //                 />
                    //             )}
                    //             <div className="space-y-2">
                    //                 {/* <div className="space-x-2 space-x-reverse">
                    //                     <span>اعادة ارسال الكود</span>
                    //                     <span className="font-small opacity-50">
                    //                         <span>يرجى الانتظار : </span>
                    //                         <span>00:{sendNewOtpTime}</span>
                    //                     </span>
                    //                 </div> */}
                    //                 <Button color="sky" onClick={handleVerifyOtp} isLoading={isLoading}>
                    //                     تأكيد الكود و تسجيل الدخول
                    //                 </Button>
                    //             </div>
                    //         </div>
                    //     </div>
                    // )
                }
            </div>
        </div>
    );
};

export default ForgetPasswordComponent;

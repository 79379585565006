import React from "react";
import SubjectCard from "../NewUi/subjectCard/SubjectCard";
import Card from "../NewUi/featureCard/Card";

const RetroCardSection = ({ isSubject = false, subjectData }) => {
    // console.log(subjectData);
    return (
        <div className=" bg-blueVip-100 relative dark:bg-blueVip-950 smooth w-full py-14 md:py-0 overflow-hidden lg:min-w-[1280px] lg:max-w-[1280px]  min-h-[580px] flex justify-center items-center">
            {!isSubject ? <Card /> : <SubjectCard {...subjectData} />}
            <p className="text-stroke text-transparent text-[2rem] md:text-[8rem] font-w-bold font-expo absolute  -top-1 md:-top-14 opacity-40 right-0">
                يلاااا .... VIP ... VIP
            </p>
            {isSubject ? (
                <div className="w-full h-full flex flex-row md:flex-col justify-evenly absolute inset-0">
                    <div className="md:hidden lg:block w-[1px] md:w-full h-full md:h-[1px] mb-0 md:mb-40 bg-blueVip-950 dark:bg-blueVip-50  smooth " />
                    <div className="md:hidden lg:block w-[1px] md:w-full h-full md:h-[1px] bg-blueVip-950 dark:bg-blueVip-50  smooth" />
                </div>
            ) : (
                <div className="w-[1px] md:w-full h-full md:h-[1px] bg-blueVip-950 dark:bg-blueVip-50 smooth absolute top-0 bottom-0 mx-auto md:mx-0 md:left-0 md:right-0 md:top-1/2 " />
            )}
        </div>
    );
};

export default RetroCardSection;

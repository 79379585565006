import "./teacher.css";
import {
    motion,
    useScroll,
    useMotionValue,
    useSpring,
    useTransform,
    useVelocity,
    useAnimationFrame,
} from "framer-motion";
import { useRef } from "react";
import { wrap } from "@motionone/utils";

function ParallaxTeacher({ children, baseVelocity = 100, moveX = 0 }) {
    // intialize motion value
    const baseX = useMotionValue(100);
    // extract scrollY value from useScroll
    const { scrollY } = useScroll();
    //Transform motion value to velocity for my animation "scroll speed up & down"
    const scrollVelocity = useVelocity(scrollY);
    // For making my animation smooth after stop scrolling it Bounce back to it's original state
    const smoothVelocity = useSpring(scrollVelocity, {
        damping: 50,
        stiffness: 400,
    });

    // this is for Control Direction of scrolling
    const velocityFactor = useTransform(smoothVelocity, [0, 1000], [0, 5], {
        clamp: false,
    });
    // the x value represents the horizontal translation (position) of the <motion.div> component.
    //providing a smooth and controlled animation range.
    const x = useTransform(baseX, (v) => `${wrap(25, 50, v)}%`);
    const directionFactor = useRef(1);
    //   console.log(directionFactor.current);
    useAnimationFrame((t, delta) => {
        // control timing of animation
        let moveBy = directionFactor.current * baseVelocity * (delta / 6000);
        // Changing direction
        if (velocityFactor.get() < 0) {
            directionFactor.current = -1;
        } else if (velocityFactor.get() > 0) {
            directionFactor.current = 1;
        }
        // speed of animation
        moveBy += directionFactor.current * moveBy * velocityFactor.get();
        baseX.set(baseX.get() + moveBy);
    });

    return (
        <div className="parallaxTeacher">
            <motion.div className="scroller" style={{ x }}>
                <span>{children}</span>
                <span>{children}</span>
                <span>{children}</span>
                <span>{children}</span>
            </motion.div>
        </div>
    );
}

export default ParallaxTeacher;

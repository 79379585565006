import React from "react";
import { ScrollingProvider } from "../../context/ScrollingContext";
import SectionHead from "../../components/ui/SectionHead";
import Container from "../../components/ui/Container";
import CenterIcon from "../../components/ui/CenterIcon";
import { Link } from "react-router-dom";
import Categorie from "./CoursesSection/Categorie";
import SideTitle from "../../components/ui/SideTitle";
import Button from "../../components/ui/Button";
import Heading from "../../components/NewUi/heading/Heading";

const LoggedInHome = () => {
    return (
        <div className="relative mb-10 mt-10 overflow-hidden" id="courses">
            <div className="">
                <Container>
                    <div className="flex flex-col mx-auto items-center justify-center h-fit w-fit">
                        <Heading title="موادي" subtitle="يبتك ومطرحك ..؟" />
                    </div>

                    <Categorie
                        api={`/api/sellables/only_renamed/subscribed`}
                        noCoursesPlaceholder="انت غير مشترك بأي مادة حتى الآن!"
                    />
                    <div className="flex flex-col mx-auto items-center justify-center h-fit w-fit">
                        <Heading title="كورساتي" subtitle="يبتك ومطرحك ..؟" />
                    </div>
                    <Categorie
                        api={`/api/sellables/not_renamed/subscribed`}
                        noCoursesPlaceholder="انت غير مشترك بأي كورس حتى الآن!"
                    />
                </Container>
            </div>

            <Container className="space-y-8 flex-center-both flex-col">
                <div className="w-full px-20">
                    <div className="smooth py-1 rounded-md bg-blueVip-500 opacity-30"></div>
                </div>
                {/* <div className="flex-center-both space-x-5 space-x-reverse">
                    <div className="font-w-bold font-h3 underline">لتفاصيل عن حسابك اكتر</div>
                    <div>
                        <Link
                            to="/me/user/"
                            className="border-2 border-cyan-500 rounded-md p-5 flex-center-both flex-col group text-cyan-500 hover:bg-cyan-500 hover:text-slate-100 smooth"
                        >
                            <div className="font-h1">
                                <CenterIcon icon={"iconamoon:profile-duotone"} />
                            </div>
                            <div className="font-h2 font-w-bold">ملفك الشخصي !</div>
                        </Link>
                    </div>
                </div> */}
            </Container>
        </div>
    );
};

export default LoggedInHome;

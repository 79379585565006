export const getYearPlaceHolder = (year) => {
    const yearsPlaceHolders = {
        1: "الصف الأول الثانوي",
        2: "الصف الثاني الثانوي",
        3: "الصف الثالث الثانوي",
        // 4: "كورسات المحاضرات",
        5: "الصف الأول الإعدادي",
        6: "الصف الثاني الإعدادي",
        7: "الصف الثالث الإعدادي",
    };
    return yearsPlaceHolders[year];
};
export const years = [
    { value: "1", text: getYearPlaceHolder(1) },
    { value: "2", text: getYearPlaceHolder(2) },
    { value: "3", text: getYearPlaceHolder(3) },
    // { value: "4", text: getYearPlaceHolder(4) },
    { value: "5", text: getYearPlaceHolder(5) },
    { value: "6", text: getYearPlaceHolder(6) },
    { value: "7", text: getYearPlaceHolder(7) },
];
